import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import PageLayout from "../components/page-layout";

import smiley from "../images/upside-down-face.png";

import * as style from "./index.module.css";

const ResourcesPage = () => {
  return (
    <PageLayout
      title="Call A Colleague - Resources"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={smiley}
    >
      <section id="above-fold" className={style.aboveFold}>
        <StaticImage
          src="../images/upside-down-face.png"
          width={50}
          height={50}
          alt="smiling face"
        />
        <h1>Further free career resources</h1>

        <p>
          <a href="https://cv-abroad.eu/en/course-ukraine.html" target="_blank">
            CV Abroad CV Course
          </a>
        </p>
        <p>
          <a href="https://cv-abroad.eu/en/course-ukraine.html" target="_blank">
            CV Samples & Job Hunt Resources
          </a>
        </p>
        <p>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/cv-abroad.appspot.com/o/manual%2FCV_ABROAD_MANUAL.pdf?alt=media&token=995fe21f-8f6a-42bb-bbac-f3d2441e0864"
            target="_blank"
          >
            CV Abroad CV Manual (in Russian)
          </a>
        </p>
        <p>
          <a href="https://www.p2pcv.org/" target="_blank">
            Peer to Peer CV Screening
          </a>
          <br />
          (be careful, CV standards are different from region to region)
        </p>
        <p>
          <a href="https://mentormove.org/" target="_blank">
            Mentor.Move
          </a>
        </p>
        <p>
          <a href="https://mentorme-ngo.org/" target="_blank">
            Mentor.Me
          </a>
        </p>
        <p></p>
      </section>
    </PageLayout>
  );
};

export default ResourcesPage;
